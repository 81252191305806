<template>
  <div class="page_body">
    <div class="account_infomation">
      <div class="account">
        <p>提交成功</p>
        <span>您提交的实名认证正在审核中，请耐心等候，如有疑问请添加客服：{{customer_account}}（微信号）</span>
      </div>
      <div class="identityCard">
        <div class="card_img">
          <p>身份证正面</p>
          <img :src="img.idcard_front_image" alt="">
        </div>
        <div class="card_img">
          <p>身份证反面</p>
          <img :src="img.idcard_back_image" alt="">
        </div>
      <div class="hint">{{img.remark}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ls from "@/libs/localstore";
import { Picker, Cell, Button, Uploader } from "vant";
import { IdcardReview,getCustomerInfo } from "@/api/wallet";

Vue.use(Picker).use(Cell).use(Button).use(Uploader);

require("@/libs/validate.js");

export default {
  name: "WithDrawCashApply",
  data() {
    return {
    img:{},
    customer_account: "",
    };
  },
  mounted() {
      this._IdcardReview()
      this._getCustomerInfo()
  },
  methods: {
   _IdcardReview(){
       let params={}
       IdcardReview(params).then(res=>{
           if(res.errCode==0){
               this.img=res.data
           }
       })
   },
    //获取客服微信号
    _getCustomerInfo() {
      getCustomerInfo().then((res) => {
        if (res.errCode == 0) {
          this.customer_account = res.data.customer_account;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #ffffff;
}
.account_infomation {
  .account {
    margin-bottom: 10px;
    width: 375px;
    height: 121px;
    background: linear-gradient(
      270deg,
      rgba(255, 108, 115, 1),
      rgba(255, 85, 149, 1)
    );
    color: rgba(255, 255, 255, 1);
    font-family: PingFang SC;
    padding: 24px 18.5px 27px;
    box-sizing: border-box;
    p {
      line-height: 19px;
      font-size: 19px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    span {
      font-size: 15px;
      line-height: 22px;
      font-weight: 400;
    }
  }
  .identityCard {
    .card_img {
      margin-left: 62.5px;
      p {
        font-size: 14px;
        color: #555555;
        line-height: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        margin: 24.5px 0 10px;
      }
      img {
        width: 250px;
        height: 157px;
        border-radius: 15px;
      }
      
    }
    .hint {
      text-align: center;
      margin: 40px 0 24px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FF5050;
    }
  }
}
</style>
